import React from 'react';

export default class PrintQueueModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
    this.selectedGroup = null;
    this.handleGroupSelection = this.handleGroupSelection.bind(this);
    this.handleSingleItemSelection = this.handleSingleItemSelection.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.handleUserOptionChange = this.handleUserOptionChange.bind(this);
  }

  componentDidMount() {
    initReactSelect2('#user-filter', this.handleUserOptionChange, null);
  }

  //Helper Function to update all items as selected/de-selected in a group
  updateGroupItems(groupedPrintItems, groupId, selected) {
    if (typeof groupedPrintItems[groupId] !== 'undefined') {
      var selectedGroup = groupedPrintItems[groupId];
      selectedGroup['selected'] = selected;
      selectedGroup['printItems'].forEach(function (i) {
        i['selected'] = selected;
      });
    }
  }

  handleSingleItemSelection(event) {
    var groupId = $(event.target).parent().data('group-id');
    var printItemId = $(event.target).parent().data('item-id');
    var groupedPrintItems = JSON.parse(
      JSON.stringify(this.state.groupedPrintItems)
    );
    if (this.selectedGroup != groupId) {
      this.updateGroupItems(groupedPrintItems, this.selectedGroup, false);
      this.selectedGroup = groupId;
    } else if (!groupedPrintItems[groupId]['allowMultiPrint']) {
      this.updateGroupItems(groupedPrintItems, groupId, false);
    }
    groupedPrintItems[groupId]['printItems'].forEach(function (i) {
      if (i.printItemId == printItemId) i['selected'] = !i['selected'];
    });
    this.setState({ groupedPrintItems: groupedPrintItems });
  }

  handleGroupSelection(event) {
    var groupId = $(event.target).parent().data('group-id');
    var groupedPrintItems = JSON.parse(
      JSON.stringify(this.state.groupedPrintItems)
    );
    if (this.selectedGroup == groupId) {
      this.updateGroupItems(groupedPrintItems, groupId, false);
      this.selectedGroup = null;
    } else {
      this.updateGroupItems(groupedPrintItems, this.selectedGroup, false); //Deselect any group
      this.updateGroupItems(groupedPrintItems, groupId, true); //Select New Group
      this.selectedGroup = groupId;
    }
    this.setState({ groupedPrintItems: groupedPrintItems });
  }

  handleDelete(event) {
    $(event.target).prop('disabled', true);
    var that = this;
    var printItemId = $(event.target).parent().data('item-id');
    confirm(
      'Are you sure you want to remove this check from the print queue?',
      function (ok) {
        if (ok) {
          that.clearPrintQueue([printItemId]);
        }
      }
    );
  }

  // Helper Function to Clear Out Print Items
  // Accepts either an array of print item IDs or keyword 'all'
  clearPrintQueue(printItemIds) {
    const that = this;

    let loginId = null;
    if ($('#user-filter option:selected').length > 0) {
      loginId = $('#user-filter option:selected').attr('value');
    }
    const requestData = {
      print_item_ids: printItemIds,
      login_id: loginId,
    };

    $.ajax({
      url: '/print/queue/remove_checks_from_queue_redesign',
      dataType: 'JSON',
      data: requestData,
      success: function (data) {
        if (data.errors.length > 1) {
          alert(
            'There was a problem deleting some of the print queue items: ' +
              data.errors
          );
        }
        var tmpGroupedPrintItems = JSON.parse(
          JSON.stringify(that.state.groupedPrintItems)
        );
        Object.keys(tmpGroupedPrintItems).forEach(function (key) {
          let deletedIndices = [];
          for (
            var i = 0;
            i < tmpGroupedPrintItems[key].printItems.length;
            i++
          ) {
            if (
              data.ids.includes(
                tmpGroupedPrintItems[key].printItems[i].printItemId
              )
            ) {
              deletedIndices.push(i);
            }
          }
          if (deletedIndices.length > 0) {
            for (var i = deletedIndices.length - 1; i >= 0; i--) {
              tmpGroupedPrintItems[key].printItems.splice(deletedIndices[i], 1);
            }

            if (tmpGroupedPrintItems[key].printItems.length == 0) {
              delete tmpGroupedPrintItems[key];
            }
          }
        });

        // Deleting the checks from the hash used to display queues for different users
        const tmpPrintQueueHash = deepCopyObject(that.state.printQueueHash);
        Object.keys(tmpPrintQueueHash).forEach(function (userId) {
          var groupedItemsForUser = tmpPrintQueueHash[userId];
          Object.keys(groupedItemsForUser).forEach(function (key) {
            let deletedIndices = [];
            for (
              let i = 0;
              i < groupedItemsForUser[key].printItems.length;
              i++
            ) {
              if (
                data.ids.includes(
                  groupedItemsForUser[key].printItems[i].printItemId
                )
              ) {
                deletedIndices.push(i);
              }
            }
            if (deletedIndices.length > 0) {
              for (let i = deletedIndices.length - 1; i >= 0; i--) {
                groupedItemsForUser[key].printItems.splice(
                  deletedIndices[i],
                  1
                );
              }

              if (groupedItemsForUser[key].printItems.length == 0) {
                delete groupedItemsForUser[key];
              }
            }
          });
        });

        that.setState({
          groupedPrintItems: tmpGroupedPrintItems,
          printQueueHash: tmpPrintQueueHash,
        });
        $('#clear-all', '#mdc-dialog-printing2').prop('disabled', false);
      },
    });
  }

  handleClearAll(event) {
    $(event.target).prop('disabled', true);
    var modal = this;

    let confirmMessage = 'Are you sure you want to clear the print queue?';
    if (
      $('#user-filter option:selected').length > 0 &&
      $('#user-filter option:selected').attr('value') == '0'
    ) {
      confirmMessage = confirmMessage.replace('?', ' for all users?');
    }
    confirm(confirmMessage, function (ok) {
      if (ok) {
        modal.clearPrintQueue('all');
      }
    });
  }

  handlePrint(event) {
    const printBtn = event.currentTarget;
    if (this.selectedGroup == null) return false;
    if (
      typeof this.state.groupedPrintItems === 'undefined' ||
      typeof this.state.groupedPrintItems[this.selectedGroup] === 'undefined'
    )
      return false;
    var selectedItems = [];
    var selectedChecks = [];

    this.state.groupedPrintItems[this.selectedGroup]['printItems'].forEach(
      function (i) {
        if (i['selected']) {
          selectedItems.push(i['printItemId']);
          selectedChecks.push(i['checkId']);
        }
      }
    );

    if (selectedChecks.length > 0) {
      const that = this;
      $(printBtn).prop('disabled', true);
      $.ajax({
        url:
          that.state.groupedPrintItems[this.selectedGroup]['printUrl'] +
          selectedChecks.join(',') +
          '?update_audit_trail=TRUE',
        dataType: 'script',
        success: function () {
          $(printBtn).prop('disabled', false);
          confirmYesNo(
            'Would you like to remove the selected checks from the print queue?',
            function (ok) {
              if (ok) {
                that.clearPrintQueue(selectedItems);
              }
            }
          );
        },
      });
    } else {
      alert('Please select at least one check to print');
    }
  }

  handleUserOptionChange(event) {
    const selectedUser = $('#user-filter option:selected').attr('value');
    var selectedGroupPrintItems = this.state.printQueueHash[selectedUser];
    this.setState({ groupedPrintItems: selectedGroupPrintItems });
  }

  render() {
    var modal = this;
    var printItemTables = [];
    var firstName = null;
    if (
      modal.state.groupedPrintItems != null &&
      Object.keys(modal.state.groupedPrintItems).length !== 0
    ) {
      const firstKey = Object.keys(modal.state.groupedPrintItems)[0];
      firstName = modal.state.groupedPrintItems[firstKey].name;
      Object.keys(modal.state.groupedPrintItems).forEach(function (key, idx) {
        var templateGroup = modal.state.groupedPrintItems[key];
        var printItems = templateGroup.printItems.map(function (item) {
          let formattedAmount = parseFloat(item.amount).toFixed(2);
          return (
            <tr key={'printItem' + item.printItemId}>
              <td>
                <div className="mdc-form-field">
                  <div
                    id="mdc-js-checkbox"
                    data-group-id={key}
                    data-item-id={item.printItemId}
                    data-amount={formattedAmount}
                    data-testing-id={`${key}?parent_object_id=${item.checkId}`}
                    className="mdc-checkbox"
                    data-mdc-auto-init="MDCCheckbox"
                  >
                    <input
                      checked={item.selected}
                      type="checkbox"
                      id="native-js-checkbox7"
                      className="mdc-checkbox__native-control"
                      onClick={modal.handleSingleItemSelection}
                    />
                    <div className="mdc-checkbox__background">
                      <svg
                        className="mdc-checkbox__checkmark"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className="mdc-checkbox__checkmark-path"
                          fill="none"
                          stroke="white"
                          d="M1.73,12.91 8.1,19.28 22.79,4.59"
                        />
                      </svg>
                      <div className="mdc-checkbox__mixedmark"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Date</div>
                  <div className="pm-display-values--value">{item.date}</div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Check No.</div>
                  <div className="pm-display-values--value">{item.checkNo}</div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Payee</div>
                  <div className="pm-display-values--value">{item.payee}</div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Amount</div>
                  <div className="pm-display-values--value">
                    ${formattedAmount}
                  </div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Bank Account</div>
                  <div className="pm-display-values--value">
                    {item.bankAccount}
                  </div>
                </div>
              </td>
              <td>
                <div className="pm-display-values">
                  <div className="pm-display-values--label">Company(s)</div>
                  <div className="pm-display-values--value">
                    {item.companies}
                  </div>
                </div>
              </td>
              <td>
                <div className="pm-align-right pm-flex-align-right">
                  <button
                    data-group-id={key}
                    data-item-id={item.printItemId}
                    className="mdc-button mdc-button-icon pm-text-color--red1 pm-removable-row--remove pm-hide-mobile"
                    onClick={modal.handleDelete}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <button
                    data-group-id={key}
                    data-item-id={item.printItemId}
                    className="mdc-button pm-text-color--red1 pm-removable-row--remove pm-hide-desktop"
                    onClick={modal.handleDelete}
                  >
                    Remove
                  </button>
                </div>
              </td>
            </tr>
          );
        });
        printItemTables.push(
          <table
            key={'printQueueGroup' + key}
            className="pm-table pm-width-full pm-reorder pm-margin-bottom-30"
          >
            <tbody>
              {idx > 0 && (
                <tr>
                  <td colSpan="5">
                    <h3 className="pm-margin-none">{templateGroup.name}</h3>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  {templateGroup.allowMultiPrint && (
                    <div className="mdc-form-field">
                      <div
                        id="mdc-js-checkbox"
                        data-group-id={key}
                        className="mdc-checkbox"
                        data-mdc-auto-init="MDCCheckbox"
                      >
                        <input
                          checked={templateGroup.selected}
                          type="checkbox"
                          id="native-js-checkbox7"
                          className="mdc-checkbox__native-control"
                          onClick={modal.handleGroupSelection}
                        />
                        <div className="mdc-checkbox__background">
                          <svg
                            className="mdc-checkbox__checkmark"
                            viewBox="0 0 24 24"
                          >
                            <path
                              className="mdc-checkbox__checkmark-path"
                              fill="none"
                              stroke="white"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                          <div className="mdc-checkbox__mixedmark"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
                <td colSpan="4">
                  <label
                    htmlFor="native-js-checkbox7"
                    id="my-checkbox-label"
                    className="grey-text"
                  >
                    Select All
                  </label>
                </td>
              </tr>
              {printItems}
            </tbody>
          </table>
        );
      });
    }

    if (
      this.props.permissions["View other users' print queues"] == 'Full Access'
    ) {
      var userMenuOptions = [];
      var userOptionsView = null;
      userMenuOptions.push(
        <option
          key={0}
          value="0"
          className="mdc-list-item"
          role="option"
          tabIndex="0"
        >
          All Users
        </option>
      );
      modal.state.userOptions.forEach(function (user) {
        var userId = user[0];
        var userName = user[1];
        userMenuOptions.push(
          <option
            key={userId}
            value={userId}
            className="mdc-list-item"
            role="option"
            tabIndex="0"
          >
            {userName}
          </option>
        );
      });
      userOptionsView = (
        <div id="print-queue-header" className="pm-flex pm-flex-2">
          {!firstName && <div className="pm-flex pm-flex-col invisible"></div>}
          {firstName && <h3 style={{ padding: '0rem 1rem' }}>{firstName}</h3>}
          <div className="pm-flex pm-flex-col">
            <div className="mdc-form-field">
              <label
                htmlFor="user-filter"
                className="label-right pm-display-values--label"
              >
                Filter by User
              </label>
              <div className="pm-select pm-border-bottom--black06">
                <select
                  id="user-filter"
                  className="pm-default-select2-menu mdc-select pm-select-small"
                  defaultValue={modal.props.defaultLogin}
                >
                  {userMenuOptions}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <aside
        id="mdc-dialog-printing2"
        className="mdc-dialog mdc-dialog--open print-queue-modal"
        role="alertdialog"
        aria-hidden="true"
        aria-labelledby="mdc-dialog-default-label"
        aria-describedby="mdc-dialog-default-description"
      >
        <div className="mdc-dialog__surface">
          <header className="mdc-dialog__header pm-bg-color--blue1">
            <h2
              id="mdc-dialog-default-label"
              className="mdc-dialog__header__title"
            >
              Print Queue
            </h2>
          </header>
          <section
            id="mdc-dialog-default-description"
            className="mdc-dialog__body mdc-dialog__body--scrollable"
          >
            {userOptionsView}
            {printItemTables}
            {printItemTables.length == 0 && (
              <h2 style={{ textAlign: 'center', padding: '50px' }}>
                There are no items in the print queue.
              </h2>
            )}
          </section>
          <footer className="mdc-dialog__footer">
            <button
              id="clear-all"
              type="button"
              className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button"
              onClick={this.handleClearAll}
            >
              Clear All
            </button>
            <button
              type="button"
              id="print-queue-cancel"
              className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--cancelPM"
            >
              Cancel
            </button>
            <button
              type="button"
              id="print-queue-print"
              className="mdc-button pm-text-color--blue1 mdc-dialog__footer__button"
              onClick={this.handlePrint}
            >
              Print
            </button>
          </footer>
        </div>
        <div className="mdc-dialog__backdrop"></div>
      </aside>
    );
  }
}
